Object.isObject = function (target) {
  return target !== null && typeof target === 'object' && target.toString() === '[object Object]';
};

Object.deepAssign = function (...resources) {
  let res;
  for (let i = 0; i < resources.length;) {
    const resource = resources[i];
    if (Object.isObject(resource)) {
      i++;
    } else {
      res = resources.splice(0, i + 1).reverse()[0];
      i = 0;
      continue;
    }
  }
  if (resources.length === 0) {
    return res;
  } else if (resources.length === 1) {
    return resources[0];
  }
  const allKeys = [...new Set(resources.flatMap(resource => Object.keys(resource)))];
  res = {};
  allKeys.forEach(key => {
    res[key] = Object.deepAssign(
      ...resources
        .filter(resource => Object.prototype.hasOwnProperty.call(resource, key))
        .map(resource => resource[key]),
    );
  });
  return res;
};

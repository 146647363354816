import Vue from 'vue';
import Router from 'vue-router';

const RouteView = {
  render (h) {
    return h('router-view');
  },
};

const routes = [
  /* {
    path: '/',
    redirect: '/home',
  }, */
  {
    path: '/',
    component: (resolve) => require(['../views/Home/Index.vue'], resolve),
  },
  {
    path: '/business',
    component: RouteView,
    children: [
      {
        path: '/business/index',
        component: (resolve) =>
          require(['../views/Business/Index.vue'], resolve),
      },
      {
        path: '/business/petrochemical',
        component: (resolve) =>
          require(['../views/Business/Petrochemical/Index.vue'], resolve),
      },
      {
        path: '/business/petrochemical/detail/:petrochemicalId',
        component: (resolve) =>
          require([
            '../views/Business/Petrochemical/PetrochemicalDetail.vue',
          ], resolve),
      },
      {
        path: '/business/construction',
        component: (resolve) =>
          require(['../views/Business/Construction/Index.vue'], resolve),
      },
      {
        path: '/business/construction/detail/:constructionlId',
        component: (resolve) =>
          require([
            '../views/Business/Construction/ConstructionDetail.vue',
          ], resolve),
      },
      {
        path: '/business/ironOre',
        component: (resolve) =>
          require(['../views/Business/IronOre/Index.vue'], resolve),
      },
      {
        path: '/business/gas',
        component: (resolve) =>
          require(['../views/Business/Gas/Index.vue'], resolve),
      },
      {
        path: '/business/investment',
        component: (resolve) =>
          require(['../views/Business/Investment/Index.vue'], resolve),
      },
    ],
  },
  {
    path: '/newsTrend',
    component: RouteView,
    redirect: '/newsTrend/list',
    children: [
      {
        path: '/newsTrend/list',
        component: (resolve) =>
          require(['../views/NewsTrends/Index.vue'], resolve),
      },
      {
        path: '/newsTrend/detail/:newsId',
        component: (resolve) =>
          require(['../views/NewsTrends/NewsDetail.vue'], resolve),
      },
    ],
  },
  {
    path: '/about',
    component: RouteView,
    children: [
      {
        path: '/about/companyIntroduction',
        component: (resolve) =>
          require(['../views/About/CompanyIntroduction/Index.vue'], resolve),
      },
      {
        path: '/about/socialResponsibility',
        component: (resolve) =>
          require(['../views/About/SocialResponsibility/Index.vue'], resolve),
      },
      {
        path: '/about/partyBuilding',
        component: (resolve) =>
          require(['../views/About/PartyBuilding/Index.vue'], resolve),
      },
      {
        path: '/about/pbDetail/:pbId',
        component: (resolve) =>
          require(['../views/About/PartyBuilding/PbDetail.vue'], resolve),
      },
      {
        path: '/about/energyDevelopmentCommittee',
        component: (resolve) =>
          require([
            '../views/About/EnergyDevelopmentCommittee/Index.vue',
          ], resolve),
      },
    ],
  },
  {
    path: '/recruit',
    component: RouteView,
    children: [
      {
        path: '/recruit/beautifulHk',
        component: (resolve) =>
          require(['../views/Recruit/BeautifulHk/Index.vue'], resolve),
      },
      {
        path: '/recruit/socialRecruit',
        component: (resolve) =>
          require(['../views/Recruit/SocialRecruit/Index.vue'], resolve),
      },
    ],
  },
];

// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
export default new Router({
  // history
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

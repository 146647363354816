export default {
  state: {
    scale: 1,
  },
  mutations: {
    setWebScale (state, payload) {
      state.scale = payload;
    },
  },
};

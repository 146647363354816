import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css';

import router from './router/';
import store from './store/';
import App from './App.vue';

import './core/index';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(ElementUI);
Vue.use(Vant);

/* eslint-disable */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});

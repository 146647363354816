import Vue from 'vue';
import Vuex from 'vuex';
import scrollModule from './scroll';
import navModule from './nav';
import webModule from './web';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    device: 'pc',
  },
  getters: {},
  mutations: {
    setDevice (state, data) {
      state.device = data;
    },
  },
  actions: {},
  modules: {
    scroll: scrollModule,
    nav: navModule,
    web: webModule,
  },
});

import Vue from 'vue';
import axios from 'axios';

// 创建 axios 实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
});
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
request.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    switch (code) {
    case 200:
      return res.data;
    default:
      return Promise.reject(res);
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

Vue.prototype.$request = request;

if (typeof window !== 'undefined') {
  window.$request = request;
}
